<template>
  <div style="width:100%;float:left;">
    <CCard>
      <CCardHeader>
        Logo Grouping
        <CButton size="sm" color="success" style="float:right;" @click="newModal = true">New Logo</CButton>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          hover
          :items="data"
          :fields="fields"
          :items-per-page="30"
          pagination
          column-filter
          table-filter
          sorter
        >
          <template #view="{item}">
            <td>
              <button @click="form = item, editModal = true" class="btn m-1 btn-sm btn-info">
                  Edit
              </button>
            </td>
          </template>
          <template #delete="{item, index}">
            <td>
              <button @click="deleteLogo(item, index)" class="btn m-1 btn-sm btn-danger">
                  <CIcon name="cil-trash"/>
              </button>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>

    <CModal
      title="New Logo"
      :show.sync="newModal"
      size="lg"
    >
      <div style="width:100%;float:left;">
        <CInput
          label="Logo Name"
          placeholder="Enter logo name"
          :value.sync="$v.form.logo_name.$model"
          :isValid="checkIfValid('logo_name')"
          invalidFeedback="This is a required field"
        />
        <CSelect
          label="Customer Number"
          placeholder="Select Customer Number"
          :value.sync="$v.form.customer_number.$model"
          :isValid="checkIfValid('customer_number')"
          invalidFeedback="This is a required field"
          :options="customer_numbers"
        />
        <label>Styles</label>
        <!-- <div style="width:100%;float:left;" v-for="(val, index) in form.customer_styles">
          <CInput
            placeholder="Enter customer style"
            v-model="form.customer_styles[index]"
            invalidFeedback="This is a required field"
          />
        </div> -->
        <div style="width:100%;float:left;" v-if="customerStyles">
          <v-select v-model="form.customer_styles" multiple :options="customerStyles"></v-select>
        </div>
        <!-- <div style="width:100%;float:left;text-align:center;">
          <CButton size="sm" color="success" @click="form.customer_styles.push('')">Add Style</CButton>
        </div> -->
        <div style="width:100%;float:left;height:50px;margin-top:15px;">
          <CAlert
              :show.sync="dismissCountDown"
              color="primary"
              fade
            >
              ({{dismissCountDown}}) Success!
            </CAlert>
          </div>
      </div>

      <template #footer>
        <CButton color="danger" @click="newModal = false, reset()">Cancel</CButton>
        <CButton color="success" @click="submitLogo()">Submit</CButton>
      </template>
    </CModal>

    <CModal
      title="Edit Logo"
      :show.sync="editModal"
      size="lg"
    >
      <div style="width:100%;float:left;">
        <CInput
          label="Logo Name"
          placeholder="Enter logo name"
          :value.sync="$v.form.logo_name.$model"
          :isValid="checkIfValid('logo_name')"
          invalidFeedback="This is a required field"
        />
        <CSelect
          label="Customer Number"
          placeholder="Select Customer Number"
          :value.sync="$v.form.customer_number.$model"
          :isValid="checkIfValid('customer_number')"
          invalidFeedback="This is a required field"
          :options="customer_numbers"
        />
        <label>Styles</label>
        <div style="width:100%;float:left;" v-if="customerStyles">
          <v-select v-model="form.customer_styles" :selectable="option => !form.customer_styles.includes(option)" multiple :options="customerStyles"></v-select>
        </div>
        <div style="width:100%;float:left;height:50px;margin-top:15px;">
          <CAlert
              :show.sync="dismissCountDown"
              color="primary"
              fade
            >
              ({{dismissCountDown}}) Success!
            </CAlert>
          </div>
      </div>

      <template #footer>
        <CButton color="danger" @click="editModal = false, reset()">Cancel</CButton>
        <CButton color="success" @click="saveLogo()">Save</CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
var methods = {}

methods.getLogos = function () {
  axios.get(this.$apiAdress + '/api/getLogos?token=' + localStorage.getItem('api_token'))
  .then(response => {
    console.log(response)
    this.data = response.data.res
    // this.customerStyles = response.data.styles
  })
}

methods.getCustomerStyles = function () {
  const postData = {
    customer_number: this.form.customer_number
  }
  axios.post(this.$apiAdress + '/api/getCustomerStyles?token=' + localStorage.getItem('api_token'), postData)
  .then(response => {
    console.log(response)
    // this.data = response.data.res
    this.customerStyles = response.data.styles
  })
}

methods.countDownChanged = function (dismissCountDown) {
  this.dismissCountDown = dismissCountDown
}

methods.showAlert = function () {
  this.dismissCountDown = this.dismissSecs
}

methods.deleteLogo = function (val, index) {
  axios.post(this.$apiAdress + '/api/deleteLogo?token=' + localStorage.getItem('api_token'), val)
  .then(response => {
    console.log(response)
    this.data.splice(index, 1)
  }).catch(e => {
    console.log(e)
  })
}

methods.submitLogo = function () {
  axios.post(this.$apiAdress + '/api/submitLogo?token=' + localStorage.getItem('api_token'), this.form)
  .then(response => {
    console.log(response)
    this.form = {
      logo_name: '',
      customer_number: this.form.customer_number,
      customer_styles: ['']
    }
    this.showAlert()
  })
}

methods.checkIfValid = function (fieldName) {
  const field = this.$v.form[fieldName]
    if (!field.$dirty) {
      return null
    } 
    return !(field.$invalid || field.$model === '')
}

methods.reset = function () {
  this.form = {
    logo_name: '',
    customer_number: '',
    customer_styles: ''
  }
}

methods.saveLogo = function () {
  const postData = {
    form: this.form
  }
  axios.post(this.$apiAdress + '/api/saveLogo?token=' + localStorage.getItem('api_token'), postData).then(response => {
    console.log(response)
    this.reset()
    this.editModal = false
  })
}

export default {
    // ===Component name
    name: "LogoGrouping",
    // ===Props passed to component
    props: {},
    // ===Components used by this component
    components: {

    },
    // ====component Data properties
    data(){
        return{
          newModal: false,
          editModal: false,
          customerStyles: null,
          fields: ['logo_name', 'customer_number', 'view', 'delete'],
          data: [],
          customer_numbers: ['', '33594', '7806'],
          dismissCountDown: 0,
          dismissSecs: 3,
          form: {
            logo_name: '',
            customer_styles: '',
            customer_number: ''
          }
        }
    },
    mixins: [validationMixin],
    validations: {
      form: {
        logo_name: {
          required
        },
        customer_styles: {
          required
        },
        customer_number: {
          required
        },
      }
    },
    watch: {
      'form.customer_number': function () {
        if (this.form.customer_number) {
          this.getCustomerStyles()
        }
      }
    },
    computed: {
      formString () { return JSON.stringify(this.form, null, 4) },
      isValid () { return !this.$v.form.$invalid },
      isDirty () { return this.$v.form.$anyDirty },
    },
    // ===Code to be executed when Component is mounted
    mounted() {
      this.getLogos()
    },
    // ===Component methods
    methods: methods
}
</script>
<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped></style>
